body {
  margin: 0;
  background-color: #000000;
  font-family:"Lekton", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p{
  color: #E1DDD8;
}

code {
  font-family:  "Lekton", monospace;
}
