html, body, #root {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-height: 100vh;
  box-sizing: border-box;
}

.title {
  font-family: 'Almendra Display', serif;
  font-size: 36px;
  color: #fff;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-weight: 400;
  letter-spacing: 4px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
  line-height: 1.4;
}

.highlight {
  color: #fff;
  font-weight: 400;
}

.subtitle {
  font-size: 16px;
  font-family: 'Afacad', sans-serif;
  font-weight: 400;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 2px;
  line-height: 1.4;
  text-align: center;
}

.subtitle .highlight {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}

.instagram-text {
  font-size: 16px;
  font-family: 'Afacad', sans-serif;
  color: #fff;
  text-decoration: none;
  margin-bottom: 1rem;
  opacity: 0.8;
  letter-spacing: 1px;
}

.instagram-button {
  display: inline-flex;
  align-items: center;
  padding: 8px 20px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  color: #fff;
  text-decoration: none;
  font-family: 'Afacad', sans-serif;
  font-size: 16px;
  transition: all 0.3s ease;
  gap: 10px;
  margin-bottom: 2rem;
}

.instagram-button:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-2px);
}

.instagram-button i {
  font-size: 20px;
}

.card-container {
  width: 240px;
  height: 408px;
  perspective: 1000px;
  margin-bottom: 2rem;
  position: relative;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: rotate 6s linear infinite;
  cursor: pointer;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.card.manual-rotate {
  animation: none;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 12px;
  overflow: hidden;
}

.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.card-back {
  transform: rotateY(180deg);
  background-position: center;
  background-size: cover;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  45% {
    transform: rotateY(0deg);
  }
  55% {
    transform: rotateY(180deg);
  }
  95% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.flip-indicator {
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-family: 'Afacad', sans-serif;
  display: none;
  align-items: center;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.flip-indicator i {
  font-size: 12px;
}

.card-container:hover .flip-indicator {
  opacity: 1;
}

.card.manual-rotate + .flip-indicator {
  display: flex;
}

@media (max-width: 480px) {
  html, body, #root {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }
  
  body {
    padding: 0;
    overflow-y: auto;
  }
  
  #root {
    min-height: auto;
    padding: 1rem 0;
  }
  
  .container {
    max-height: none;
    padding: 1rem;
    justify-content: flex-start;
    min-height: calc(100vh - 2rem);
  }
  
  .title {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 28px;
    padding: 0 15px;
  }
  
  .subtitle {
    font-size: 14px;
    padding: 0 20px;
    margin-bottom: 1rem;
  }
  
  .card-container {
    margin: 1rem 0;
    width: 200px;
    height: 340px;
    margin-bottom: 1.5rem;
  }
  
  .instagram-text {
    padding: 0 1.5rem;
    font-size: 14px;
    margin-bottom: 1rem;
  }
  
  .instagram-button {
    font-size: 14px;
    margin-bottom: 0;
  }
}